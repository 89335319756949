<template>
  <v-app>
    <v-app-bar
      app
      color="#202E3F"
      dark
      flat
      dense
    >
      <div class="d-flex align-center">
         <v-img
          :src="require('./assets/7.png')"
          max-width="42"
          max-height="42"
          contain
        />

       
      </div>

      <v-spacer></v-spacer>

      <!-- Sign In component --> 
      <sign-in />
      
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="#B15C36"
      ></v-progress-linear>
    </v-app-bar>

      <v-container>
      <Home  v-if="userSignedIn=='no'" style="margin-top: 40px "/>
      <Checking v-if="userSignedIn=='checking'" />
      <UserContent v-if="userSignedIn=='yes'" style="margin-top: 40px "/>
      </v-container>
  

  </v-app>
</template>

<script>
import Home from './components/Home';
import SignIn from './components/SignIn';
import Checking from './components/Checking';
import UserContent from './components/UserContent';

export default {
  name: 'App',

  components: {
    Home, SignIn, UserContent, Checking
  },

  data: () => ({
    userSignedIn: 'checking',
    loading: false
  }),

  mounted: function() {
      
    this.$root.$on('userSignedIn', status => { 
        if (status == 'checking'){this.loading = true}
        else{this.loading =false}
        this.userSignedIn = status
    })
  }

};
</script>
