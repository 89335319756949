<template>
  <v-container>
   
    <v-row class="text-center" style="margin-top:0px !important;">
     
      <v-col cols="12">
       
        
        <v-img
          :src="require('../assets/main.png')"
          style="margin-top:0px !important"
          class="my-3"
          
        /> <!--083049-->
        <v-row class="text-center" style="background-color: white; margin-left: 0px; margin-right:0px;">
      
      
        <v-col cols=12 style="padding-top:0px !important">
        <v-btn @click="goToHub" light color="#c9e265"  style="border:1px solid #c9e265; padding: 30px; margin-top: 50px" >ENTER COMMUNITY PORTAL</v-btn>
        </v-col>
          <v-col cols=12  >
        <v-btn @click="signIn" text small color=" #202E3F"  style="padding: 30px" >staff sign in</v-btn>
        </v-col>
        </v-row>
        <p style="margin-top:50px"> For more information about the city of Ithaca, visit: <a href="https://cityofithaca.org">https://cityofithaca.org </a></p>
      </v-col>
      
    </v-row>

   
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
    
    }),

    methods: {
      goToHub(){
        window.open("https://data-ithacany.opendata.arcgis.com/", '_blank');
      },
      signIn(){
        this.$root.$emit("signinsplash")
      }
    }
  }
</script>
