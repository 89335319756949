<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
       
                <v-btn v-show="!userSignedIn" v-on="on" color="#242D3C" id="signinbtn" >
                    <span class="mr-2">Sign In</span>
                    <v-icon color="grey">mdi-account-circle</v-icon>
                </v-btn>
            </template>
            <span>click to sign in</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-show="userSignedIn" v-on="on" color="#242D3C" id="signoutbtn" >
                    <span class="mr-2">Welcome</span>
                    <v-icon color="#C9E265">mdi-account-circle</v-icon>
                </v-btn>
            </template>
            <span>Click to sign out</span>
        </v-tooltip>
    </div>
</template>

<script>
import { loadModules } from 'esri-loader';

export default {
  name: 'sign-in',
  
  data: () => ({
    user: '' ,
    userSignedIn: false,
    checkingSignIn: true,
    groups: []
  }),

   mounted() {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
      loadModules(["esri/identity/OAuthInfo", "esri/identity/IdentityManager", "dojo/on", "dojo/dom"], { css: true })
      .then(([OAuthInfo, identityManager, on, dom,  ]) => {
        
        // arcgis online
        var portalUrl = "https://www.arcgis.com/sharing";
    
        //check if signed in
        var _this= this 
        var info = new OAuthInfo({
          appId: "NMH4bwrGwXrdGXOA", //*** Your Client ID value goes here ***//
          popup: false // inline redirects don't require any additional app configuration
        });
        
        identityManager.registerOAuthInfos([info]);
        _this.$root.$emit('userSignedIn', 'checking')
        identityManager.checkSignInStatus(portalUrl)
        .then(function(result) {
          // if the user is signed in show the log out button
          _this.user = result.userId
          
          console.log(result)
          _this.userSignedIn = true
          _this.$root.$emit('userSignedIn', 'yes')

        })
        .catch(function(){
          console.log('not logged in')
          //if the user is not logged in show the login buttton
          _this.userSignedIn = false
          _this.$root.$emit('userSignedIn', 'no')
        })

        // sign in click from splash screen
        this.$root.$on('signinsplash', () => { 
            identityManager.getCredential(portalUrl);
        })
      
        // on click login event
        on(dom.byId("signinbtn"), "click", function() {
            identityManager.getCredential(portalUrl);
        });

        // on click log out event
        on(dom.byId("signoutbtn"), "click", function() {
            identityManager.destroyCredentials();
            window.location.reload();

        });

   
      });
      
    },
   
    beforeDestroy() {
      if (this.view) {
        // destroy the map view
        this.view.container = null;
      }
    }
  };

</script>
