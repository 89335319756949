<template>
<v-container>
<v-container align="center" style="margin-bottom: 40px" ><h1> APPLICATIONS PORTAL</h1>
<p>Spatial Applications for City Employees</p></v-container>

 <v-tabs
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab >Your Groups</v-tab>
      <v-tab>Organization</v-tab>
      <v-tab>Public</v-tab>
   
   <v-tab-item style="margin: 40px">

  <!-- GROUP CONTAINER-->


    <div
    v-for="(group, i) in groups"
    :key="i"
    >
    
    <v-container v-if="group.item.results.length>0">
        <h4>
         <v-list-item-avatar tile  size="35" style="margin:2px">
             <v-icon color="#242D3C">mdi-account-multiple</v-icon>
         
          </v-list-item-avatar>
          Apps For {{group.title}}
          </h4>
        <v-list three-line>
     
        <v-list-item style="border-bottom: 1px solid lightgray" :class="{'graybackground': index % 2 === 0, 'nobackground': index % 2 !== 0 }"
           v-for="(item, index) in group.item.results"
          :key="index"
        >
          <v-list-item-avatar tile  size="100" style="border: 1px solid #242D3C; margin-top: 30px;">
             <v-img :src="item.thumbnailUrl" color="#242D3C"></v-img>
          </v-list-item-avatar>
           
      
           <v-list-item-content >
           <v-card-title class="font-weight-medium text-subtitle-1 text-uppercase text--primary" > {{item.title}}</v-card-title>
            <v-card-subtitle  class="underline">{{item.displayName}}</v-card-subtitle>
            <v-card-text class="text--secondary" v-html="item.snippet"></v-card-text>
           </v-list-item-content>
            <v-list-item-action>
            <v-btn v-if="item.type!=='QuickCapture Project'" small rounded text @click="openURL(item.url, item.displayName, item.id)" > 
           <v-icon small color="#242D3C">mdi-open-in-new</v-icon>  Open
              </v-btn>
         
          <v-dialog
            v-model="dialogGroups[index + group.title]"
            width="500"
          >
      <template v-slot:activator="{ on, attrs }">
          <v-btn small text rounded v-bind="attrs" v-on="on" v-if="item.description" >
            <v-icon  small color="#242D3C">mdi-dots-horizontal</v-icon>DETAILS
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="title">
          {{item.title}}
        </v-card-title>
        

        <v-card-subtitle class="subtitle">
          {{item.displayName}}
        </v-card-subtitle>


        <v-card-text>
          Additional Details: <br/>
          <div v-html="item.description">
        </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogGroups[index + group.title] = false"
          >
           CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


       </v-list-item-action>
     </v-list-item>
      </v-list>
    
 </v-container>
</div>
 </v-tab-item>
<v-tab-item style="margin: 40px;">
<!-- ORG CONTAINER-->
         <h4 >
          <v-list-item-avatar tile size="35" style="margin: 2px;">
            <v-icon color="#242D3C">mdi-office-building</v-icon>
          </v-list-item-avatar>
          Apps available to your organization</h4>
     <v-container >
    
       <v-list  three-line>   
       <v-list-item style="border-bottom: 1px solid lightgray"  :class="{'graybackground': index % 2 === 0, 'nobackground': index % 2 !== 0 }"
           v-for="(item, index) in org.results"
          :key="index"
        >
        
        <v-list-item-avatar tile  size="100" style="border: 1px solid #242D3C; margin-top: 30px;">
             <v-img :src="item.thumbnailUrl" color="#242D3C"></v-img>
          </v-list-item-avatar>
           
          <v-list-item-content >
              <v-card-title class="font-weight-medium text-subtitle-1 text-uppercase text--primary" > {{item.title}}</v-card-title>
            <v-card-subtitle  class="underline">{{item.displayName}}</v-card-subtitle>
            <v-card-text class="text--secondary" v-html="item.snippet"></v-card-text>
          </v-list-item-content>
             <v-list-item-action>
           <v-btn v-if="item.type!=='QuickCapture Project'" small rounded text @click="openURL(item.url, item.displayName, item.id)" > 
           <v-icon small color="#242D3C">mdi-open-in-new</v-icon>  Open
              </v-btn>

                 <v-dialog
            v-model="dialogOrg[index]"
            width="500"
          >
      <template v-slot:activator="{ on, attrs }">
          <v-btn small text rounded v-bind="attrs" v-on="on" v-if="item.description" >
            <v-icon  small color="#242D3C">mdi-dots-horizontal</v-icon>DETAILS
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="title">
          {{item.title}}
        </v-card-title>
        

        <v-card-subtitle class="subtitle">
          {{item.displayName}}
        </v-card-subtitle>


        <v-card-text>
          Additional Details: <br/>
          <div v-html="item.description">
        </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogOrg[index] = false"
          >
           CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

          
       </v-list-item-action>
     </v-list-item>
    </v-list>
</v-container>
</v-tab-item>
<!-- PUBLIC CONTAINER-->
<v-tab-item style="margin: 40px;">
     <h4>
       <v-list-item-avatar tile size="35" style="margin: 2px;">
            <v-icon color="#242D3C">mdi-earth</v-icon>
          </v-list-item-avatar>
           Apps available to the public </h4>
      <v-container >
   
       <v-list  three-line>   
       <v-list-item style="border-bottom: 1px solid lightgray;" :class="{'graybackground': index % 2 === 0, 'nobackground': index % 2 !== 0 }"
           v-for="(item, index) in publicapps.results"
          :key="index"
        >
             <v-list-item-avatar tile  size="100" style="border: 1px solid #242D3C; margin-top: 30px;">
             <v-img :src="item.thumbnailUrl" color="#242D3C"></v-img>
          </v-list-item-avatar>
       
             <v-list-item-content >
            <v-card-title class="font-weight-medium text-subtitle-1 text-uppercase text--primary" > {{item.title}}</v-card-title>
            <v-card-subtitle  class="underline">{{item.displayName}}</v-card-subtitle>
            <v-card-text class="text--secondary" v-html="item.snippet"></v-card-text>
           </v-list-item-content>
             <v-list-item-action>
          <v-btn v-if="item.type!=='QuickCapture Project'" small rounded text @click="openURL(item.url, item.displayName, item.id)" > 
           <v-icon small color="#242D3C">mdi-open-in-new</v-icon>  Open
              </v-btn>
     <v-dialog
            v-model="dialogPublic[index]"
            width="500"
          >
       <template v-slot:activator="{ on, attrs }">
           <v-btn small text rounded v-bind="attrs" v-on="on" v-if="item.description" >
            <v-icon  small color="#242D3C">mdi-dots-horizontal</v-icon>DETAILS
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="title">
          {{item.title}}
        </v-card-title>
        

        <v-card-subtitle class="subtitle">
          {{item.displayName}}
        </v-card-subtitle>


        <v-card-text>
          Additional Details: <br/>
         <div v-html="item.description">
        </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogPublic[index] = false"
          >
           CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          
          
          
         
          
       </v-list-item-action>
     </v-list-item>
    </v-list>


</v-container>
</v-tab-item>
</v-tabs>
</v-container>
</template>

<script>
import { loadModules } from 'esri-loader';
export default {
  name: 'user-content',
  
  data: () => ({
    portalItems: '' ,
    userName: false,
    groups: [],
    publicapps: [],
    org: [],
    selectedItem: '',
    dialogGroups: {},
    dialogOrg: {},
    dialogPublic: {}

    
 }),
   // mounted() {
    //    this.$root.$on('gotGroups', groups => { 
   //     this.groups = groups
   // })

  //  }

     mounted() {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
      loadModules([ "esri/portal/Portal", "esri/portal/PortalQueryParams" ], { css: true })
      .then(([  Portal, PortalQueryParams]) => {
        var _this=this;
      
        var portal = new Portal();
        // Setting authMode to immediate signs the user in once loaded
        portal.authMode = "immediate";
        //portal.canSearchPublic = false;

        // Once portal is loaded, user signed in
    
        portal.load().then(function() {
            portal.user.fetchGroups().then(function(groups){
                var groupItems = []
                groups.forEach(function(group){
                      var queryParams = new PortalQueryParams({
                        query: "tags: home page",
                        sortField: "title",
                        sortOrder: "asc",
                  
                    });
                    group.queryItems(queryParams).then(function(items){
                      console.log(group.title)
                      console.log(items)
                        groupItems.push({title: group.title, item: items})
                      
                    })
                })
                //for (var i = 0; i < groups.length; i++) {
                //    
                //}
                _this.groups= groupItems
              

            });
          
            // Create query parameters for the public portal 
            // This object autocasts as new PortalQueryParams() orgid: R1JbITZvSQHJsl5r
            // query: "access: public and orgid: R1JbITZvSQHJsl5r and tags: home page",
            var queryParams = new PortalQueryParams({
                query: "access: public orgid: R1JbITZvSQHJsl5r tags: home page",
                sortField: "title",
                sortOrder: "asc",
            
            });
            // Query the items based on the queryParams created from portal above
            portal.queryItems(queryParams).then((response) => { console.log(response)
              _this.publicapps = response});
     

          //organizational query search
          var queryParams2 = new PortalQueryParams({
                query: "access: org orgid: R1JbITZvSQHJsl5r tags: home page",
                sortField: "title",
                sortOrder: "asc",
               
            });
            // Query the items based on the queryParams created from portal above
            portal.queryItems(queryParams2).then((response) => { 
              _this.org = response  
              console.log(response)
            });
         });
      })
      
    },
    methods:{
       openURL(url,type,id){
         console.log(url)
         if (type === "Dashboard"){
          url = "https://ithacany.maps.arcgis.com/apps/opsdashboard/index.html#/" + id
         }
         else if(type ==="Web Map"){
          url = "https://ithacany.maps.arcgis.com/home/webmap/viewer.html?webmap=" + id
         }
         else if (type==="QuickCapture Project"){
           url =""
         }
    
          window.open(url, '_blank');
      
      }
    },
   
    beforeDestroy() {
      if (this.view) {
        // destroy the map view
        this.view.container = null;
      }
    }
  
}

</script>
<style scoped>
.graybackground{
  background-color: #F5F5F5;
}
.nobackground{
  background-color: white
}
</style>